<template>
  <div class="relative z-20" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <Transition name="backdrop">
      <div v-show="props.open" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    </Transition>
    <Transition name="panel">
      <div v-show="props.open" class="fixed inset-0 overflow-hidden">
        <div @click.self="close" class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="flex h-full flex-col bg-secondary text-white py-6 shadow-xl">
                <div class="px-4 mobile:px-6 flex flex-row gap-4">
                  <Transition name="button">
                    <button @click="close" type="button" class="my-auto rounded-full aspect-square p-1">
                      <span class="sr-only">Close panel</span>
                      <font-awesome-icon
                        icon="xmark"
                        class="text-[1.2em]"
                      />
                    </button>
                  </Transition>
                  <h3 class="my-auto font-medium leading-6" id="slide-over-title">JustChunks</h3>
                </div>
                <div class="relative mt-6 flex-1 px-4 mobile:px-6 flex flex-col gap-6 overflow-y-auto">
                  <div class="flex flex-col gap-2">
                    <template
                      v-for="route in routes"
                      :key="route"
                    >
                      <NuxtLink
                        v-slot="slot"
                        :to="localePath({ name: route })"
                        class="rounded p-1 text-white"
                      >
                      <span
                        class="mx-auto text-center whitespace-nowrap hover:text-primary-highlighted active:text-primary-selected h4"
                      >
                        {{ t('jc.route.' + (slot?.route?.meta?.nameKey || 'default') + '.title') }}
                      </span>
                      </NuxtLink>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const { t } = useI18n()
const localePath = useLocalePath()

const props = defineProps<{
  open: boolean
  routes: string[]
}>()

const emit = defineEmits<{
  (e: "close", value: void): void
}>()

function close() {
  emit("close")
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
:deep(.router-link-active) {
  @apply text-primary
}

.backdrop-enter-active {
  @apply transition-opacity ease-in-out duration-500
}

.backdrop-enter-from {
  @apply opacity-0
}

.backdrop-enter-to {
  @apply opacity-100
}

.backdrop-leave-active {
  @apply transition-opacity ease-in-out duration-500
}

.backdrop-leave-from {
  @apply opacity-100
}

.backdrop-leave-to {
  @apply opacity-0
}

.panel-enter-active {
  @apply transform transition ease-in-out duration-500 mobile:duration-700
}

.panel-enter-from {
  @apply -translate-x-full
}

.panel-enter-to {
  @apply translate-x-0
}

.panel-leave-active {
  @apply transform transition ease-in-out duration-500 mobile:duration-700
}

.panel-leave-from {
  @apply translate-x-0
}

.panel-leave-to {
  @apply -translate-x-full
}

.button-enter-active {
  @apply ease-in-out duration-500
}

.button-enter-from {
  @apply opacity-0
}

.button-enter-to {
  @apply opacity-100
}

.button-leave-active {
  @apply ease-in-out duration-500
}

.button-leave-from {
  @apply opacity-100
}

.button-leave-to {
  @apply opacity-0
}
</style>
