// noinspection JSUnusedGlobalSymbols

import { defineNuxtPlugin } from '#imports'
import type { RouterScrollBehavior } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  (nuxtApp.$router.options.scrollBehavior as RouterScrollBehavior) = async (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})
// Alternative: https://github.com/nuxt/framework/discussions/1661#discussioncomment-3967225
