import type {Locale, News, ObjectId, TranslatedNews} from "~/composables/charon/types";
import {translateNews} from "~/composables/charon/util";

export function useCharon(auth: boolean = false) {
  // build client
  const charon = buildCharon(auth)

  // build news api
  async function listNews(params: Record<string, any> = {}): Promise<News[]> {
    return charon<News<string>[]>(`/news`, { method: "GET", params })
      .then(news => news.map<News>(news => parseNews(news)))
  }
  async function countNews(params: Record<string, any> = {}): Promise<number> {
    return charon<number>(`/news/count`, { method: "GET", params })
  }
  async function findNews(newsId: ObjectId): Promise<News> {
    return charon<News<string>>(`/news/${newsId}`, { method: "GET" })
      .then(news => parseNews(news))
  }
  async function createNews(news: News): Promise<News> {
    return charon<News<string>>(`/news`, { method: "PUT", body: stringifyNews(news) })
      .then(news => parseNews(news))
  }
  async function updateNews(news: News): Promise<News> {
    return charon<News<string>>(`/news`, { method: "PATCH", body: stringifyNews(news) })
      .then(news => parseNews(news))
  }
  async function deleteNews(newsId: ObjectId): Promise<void> {
    return charon<void>(`/news/${newsId}`, { method: "DELETE" })
  }

  return {
    charon,
    // news api
    listNews,
    countNews,
    findNews,
    createNews,
    updateNews,
    deleteNews,
  }
}

function buildCharon(auth: boolean = false) {
  const config = useRuntimeConfig()
  let charon = $fetch.create({
    baseURL: `${config.public.charonUrl}`,
  })
  // unauthenticated
  if (!auth) {
    return charon
  }
  // authenticated
  return charon.create({
    // injects bearer token if user is authenticated
    async onRequest(ctx): Promise<void> {
      console.debug(`[charon]: request`, ctx.request)
      // only clients can be authenticated
      if (import.meta.server) {
        console.error("[charon]: tried to call charon in authenticated mode on server: authenticated mode is only available in browser")
        return
      }
      // refresh access token if necessary
      const { data, getSession } = useAuth()
      if (data.value?.tokenExpiresAt && Math.floor(Date.now() / 1000) > data.value?.tokenExpiresAt - 5) {
        console.debug(`[charon]: triggering session refresh for request`, ctx.request)
        await getSession()
      }
      const token = data.value?.accessToken
      if (!token) {
        console.error(`[charon]: failed to retrieve token for charon authenticated mode`)
        return
      }
      const headers = {
        'Authorization': `Bearer ${token}`
      }
      ctx.options.headers = {...headers,  ...ctx.options.headers }
    }
  })
}

function parseNews(news: News<string>): News {
  return {
    ...news,
    locales: Object.fromEntries(
      Object.entries(news.locales)
        .map(([locale, content]) => [
          locale,
          {
            ...content,
            title: content.title ? JSON.parse(content.title) : null,
            teaser: content.teaser ? JSON.parse(content.teaser) : null,
            body: content.body ? JSON.parse(content.body) : null,
          }
        ])
    )
  }
}

function stringifyNews(news: News): News<string> {
  return {
    ...news,
    locales: Object.fromEntries(
      Object.entries(news.locales)
        .map(([locale, content]) => [
          locale,
          {
            ...content,
            title: content.title ? JSON.stringify(content.title) : null,
            teaser: content.teaser ? JSON.stringify(content.teaser) : null,
            body: content.body ? JSON.stringify(content.body) : null,
          }
        ])
    )
  }
}
