import validate from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/pages/runtime/validate.js";
import discord_45global from "/app/middleware/discord.global.ts";
import legacy_45job_45posting_45global from "/app/middleware/legacyJobPosting.global.ts";
import legacy_45news_45global from "/app/middleware/legacyNews.global.ts";
import legacy_45user_45global from "/app/middleware/legacyUser.global.ts";
import username_45user_45global from "/app/middleware/usernameUser.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  discord_45global,
  legacy_45job_45posting_45global,
  legacy_45news_45global,
  legacy_45user_45global,
  username_45user_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_@opentelemetry+api@1.9.0_encoding@0.1.13_magicast@0.3.4_next-auth@4_zbulf4cunvpnpdocldabqoipt4/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}