<template>
  <div
    class="rounded-md overflow-hidden shadow-lg border border-gray-200 bg-white flex flex-col"
  >
    <span
      class="bg-primary h-full w-full
        min-h-[10px] min-w-[10px] laptop:min-h-[15px] laptop:min-w-[15px]"
    />
    <div
      class="relative z-10 mx-8 my-12 laptop:mx-16 flex flex-col gap-4"
    >
      <h2
        class="text-slate-900"
      >
        {{ props.heading }}
      </h2>
      <p
        class="text-slate-900"
      >
        {{ props.paragraph }}
      </p>
      <div class="mt-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  heading: string
  paragraph: string
}>()
</script>

<style scoped lang="postcss">

</style>
