<template>
  <footer
    id="footer"
    class="flex flex-col bg-secondary text-white"
  >
    <span
      class="bg-primary h-full w-full
        min-h-[10px] min-w-[10px] laptop:min-h-[15px] laptop:min-w-[15px]"
    />
    <div class="footer-links mt-12 mx-auto w-content-item flex flex-row flex-wrap justify-between gap-x-4 mobile:gap-x-24 laptop:gap-x-10 gap-y-4">
      <template
        v-for="(item, index) in [
          {
            to: localePath({ name: 'legal-notice' })
          },
          {
            to: localePath({ name: 'legal-privacy' })
          },
          {
            to: localePath({ name: 'branding' })
          },
          {
            to: localePath({ name: 'legal-terms' })
          },
          {
            to: localePath({ name: 'help' })
          },
          {
            to: localePath({ name: 'creator' })
          }
        ]"
        :key="index"
      >
        <NuxtLink
          :to="item.to"
          v-slot="{ route }"
          class="font-semibold uppercase"
        >
          <span>
            {{ t('jc.route.' + (route.meta.nameKey || 'fallback') + '.title') }}
          </span>
        </NuxtLink>
      </template>
    </div>
    <div class="mt-12 mx-auto w-content-item flex flex-col laptop:flex-row justify-center laptop:justify-between gap-8">
      <button
        @click="() => copyMinecraftIp()"
        class="my-auto mx-auto laptop:ml-0 bg-white hover:bg-natural-50 text-gray-900 text-center rounded py-2 px-4 flex flex-row gap-3 font-semibold"
        :title="t('jc.component.footer.minecraftLink.title')"
      >
        <FontAwesomeIcon
          fixed-width
          size="lg"
          icon="gamepad-modern"
        />
        <span class="ml-3 break-keep">{{ t('jc.component.footer.minecraftLink.label') }}</span>
      </button>
      <div class="footer-links flex flex-row gap-1 laptop:gap-2 mx-auto laptop:mr-0">
        <template
          v-for="(item, index) in socialLinks"
          :key="index"
        >
          <NuxtLink
            class="m-auto aspect-square b1"
            :href="item.href"
            :rel="item.rel"
            target="_blank"
            :title="item.title"
            :aria-label="item.title"
          >
            <FontAwesomeIcon
              fixed-width
              size="xl"
              :icon="{ prefix: 'fab', iconName: item.icon }"
            />
          </NuxtLink>
        </template>
      </div>
    </div>
    <div
      class="mt-24 mb-12 mx-auto text-gray-300 w-content-item"
    >
      <div class="mx-auto w-fit text-center">
        {{ t('jc.component.footer.endorsement') }}
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import {useCopyMinecraftIp} from "~/composables/useCopyMinecraftIp";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const {t} = useI18n()
const localePath = useLocalePath()
const me = useMe()
const config = useRuntimeConfig()
const copyMinecraftIp = useCopyMinecraftIp()

const socialLinks = [
  {
    title: 'Discord',
    icon: 'discord',
    href: config.public.discordUrl,
  },
  {
    title: 'Twitch',
    icon: 'twitch',
    href: config.public.twitchUrl
  },
  {
    title: 'YouTube',
    icon: 'youtube',
    href: config.public.youtubeUrl
  },
  {
    title: 'Threads',
    icon: 'threads',
    href: config.public.threadsUrl
  },
  {
    title: 'Instagram',
    icon: 'instagram',
    href: config.public.instagramUrl
  },
  {
    title: 'TikTok',
    icon: 'tiktok',
    href: config.public.tiktokUrl
  },
  {
    title: 'X (Twitter)',
    icon: 'x-twitter',
    href: config.public.twitterUrl
  },
  {
    title: 'Facebook',
    icon: 'facebook-square',
    href: config.public.facebookUrl
  },
  {
    title: 'Mastodon',
    icon: 'mastodon',
    href: config.public.mastodonUrl,
    rel: 'me'
  },
  {
    title: 'WhatsApp',
    icon: 'whatsapp',
    href: config.public.whatsappUrl
  },
]
</script>

<style scoped lang="postcss">
/* style nested base links by overriding (exposed) styles */
.footer-links button {
  @apply whitespace-nowrap text-white hover:text-primary-highlighted active:text-primary-selected
}

.footer-links :deep(a) {
  @apply whitespace-nowrap text-white hover:text-primary-highlighted active:text-primary-selected
}

.footer-links :deep(.router-link-active) {
  @apply text-primary
}
</style>
