<template>
  <nav class="sticky top-0 left-0 right-0 z-20 bg-secondary">
    <NavSlideover
      :routes="routes"
      :open="panelOpen"
      @close="() => panelOpen = false"
    />

    <div class="w-content-item mx-auto my-3 grid grid-cols-[1fr_1fr_1fr] laptop:grid-cols-[auto_1fr_1fr] gap-6 items-center">
      <div class="flex laptop:hidden flex-row justify-start">
        <button
          id="panel-button"
          class="text-white p-2 hover:bg-secondary-highlighted active:bg-secondary-selected rounded"
          @click="() => panelOpen = true"
        >
          <FontAwesomeIcon
            fixed-width
            size="lg"
            icon="bars"
          />
        </button>
      </div>

      <div class="flex flex-row justify-center laptop:justify-start">
        <NuxtLink
          id="home"
          :to="localePath({
            name: 'index'
          })"
          :title="t('jc.component.navbar.homeButton.title')"
        >
          <NuxtPicture
            src="images/logo.png"
            :alt="t('jc.component.navbar.homeIcon.alt')"
            height="30px"
            width="35px"
            sizes="35px"
          />
        </NuxtLink>
      </div>

      <div id="routes" class="hidden laptop:flex flex-row gap-2">
        <template
          v-for="route in routes"
          :key="route"
        >
          <NuxtLink
            v-slot="slot"
            :to="localePath({ name: route })"
            class="rounded p-1 text-white"
          >
            <span
              class="text-center whitespace-nowrap hover:text-primary-highlighted active:text-primary-selected h4"
            >
              {{ t('jc.route.' + (slot?.route?.meta?.nameKey || 'default') + '.title') }}
            </span>
          </NuxtLink>
        </template>
      </div>

      <div id="menu" class="flex flex-row gap-0.5 justify-end">
        <div id="lang-menu" class="relative inline-block text-left">
          <div>
            <button
              id="lang-button"
              class="text-white p-2 hover:bg-secondary-highlighted active:bg-secondary-selected rounded"
              :title="t('jc.component.navbar.loginButton.title')"
              @click="() => localesOpen = !localesOpen"
              v-click-outside="() => localesOpen = false"
            >
              <span class="sr-only">Change Language</span>
              <FontAwesomeIcon
                fixed-width
                size="lg"
                icon="language"
              />
            </button>
          </div>
          <Transition name="dropdown">
            <div
              v-if="localesOpen"
              class="absolute right-0 z-20 mt-1 w-56 origin-top-right rounded-md bg-secondary-highlighted text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1 rounded-md flex flex-col gap-2" role="none">
                <template v-for="item in locales" :key="item.code">
                  <a
                    href="#"
                    :aria-selected="item.code === locale"
                    class="px-4 py-1 hover:text-primary-highlighted active:text-primary-selected aria-selected:text-primary"
                    @click.prevent.stop="setLocale(item.code)"
                  >
                    {{ item.name }}
                  </a>
                </template>
              </div>
            </div>
          </Transition>
        </div>

        <div id="user-menu" class="relative inline-block text-left">
          <div>
            <button
              id="lang-button"
              class="text-white p-2 hover:bg-secondary-highlighted active:bg-secondary-selected rounded"
              :title="t('jc.component.navbar.loginButton.title')"
              @click="() => userOpen = !userOpen"
              v-click-outside="() => userOpen = false"
            >
              <span class="sr-only">Change Language</span>
              <FontAwesomeIcon
                fixed-width
                size="lg"
                icon="user"
              />
            </button>
          </div>
          <Transition name="dropdown">
            <div
              v-if="userOpen"
              class="absolute right-0 z-20 mt-1 w-56 origin-top-right rounded-md bg-secondary-highlighted text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="my-3 rounded-md flex flex-col gap-2" role="none">
                <ClientOnly>
                  <div v-if="me" class="flex flex-col px-4 gap-1 py-1">
                    <NuxtLink
                      id="profile"
                      key="profile"
                      :to="localePath({
                        name: 'user-user',
                        params: {
                          user: me.name
                        }
                      })"
                      :title="t('jc.component.navbar.profileButton.title')"
                      class="hover:text-primary-highlighted active:text-primary-selected"
                    >
                      <span class="">{{ me.name }}</span>
                    </NuxtLink>
                    <span class="text-white text-xs opacity-50">Level {{ me.level }}</span>
                  </div>
                  <button
                    v-if="!me"
                    id="login"
                    key="login"
                    :title="t('jc.component.navbar.loginButton.title')"
                    @click="() => signIn('keycloak')"
                    class="text-left px-4 py-1 hover:text-primary-highlighted active:text-primary-selected"
                  >
                    <FontAwesomeIcon
                      fixed-width
                      size="lg"
                      icon="arrow-right-to-bracket"
                    />
                    <span class="ml-3">Anmelden</span>
                  </button>
                  <span class="bg-secondary h-[1px] mx-2 rounded" />
                  <NuxtLink
                    id="lang-button"
                    class="px-4 py-1 hover:text-primary-highlighted active:text-primary-selected"
                    :title="t('jc.component.navbar.loginButton.title')"
                    :to="localePath({
                      name: 'user'
                    })"
                  >
                    <FontAwesomeIcon
                      fixed-width
                      size="lg"
                      icon="user-magnifying-glass"
                    />
                    <span class="ml-3">Nutzer Suche</span>
                  </NuxtLink>
                  <template v-if="me">
                    <span class="bg-secondary h-[1px] mx-2 rounded" />
                    <button
                      id="logout"
                      key="logout"
                      :title="t('jc.component.navbar.logoutButton.title')"
                      @click="() => signOut()"
                      class="text-left px-4 py-1 hover:text-primary-highlighted active:text-primary-selected"
                    >
                      <FontAwesomeIcon
                        fixed-width
                        size="lg"
                        icon="arrow-right-from-bracket"
                        class=""
                      />
                      <span class="ml-3">Abmelden</span>
                    </button>
                  </template>
                </ClientOnly>
              </div>
            </div>
          </Transition>
        </div>
        <div class="laptop:hidden" id="navbar-basket" />
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMe } from "~/composables/useMe";

const { t } = useI18n()
const localePath = useLocalePath()
const route = useRoute()
const { signIn, signOut } = useAuth()
const me = useMe()
const { locale, locales, setLocale } = useI18n()

const panelOpen = ref(false)
const localesOpen = ref(false)
const userOpen = ref(false)

watch(() => route.path, () => {
  panelOpen.value = false
  localesOpen.value = false
  userOpen.value = false
})

const routes = [
  'index',
  'game',
  'news',
  'participate',
  'shop',
]
</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
:deep(.router-link-active) {
  @apply text-primary
}

.dropdown-enter-active {
  @apply transition ease-out duration-100
}

.dropdown-enter-from {
  @apply transform opacity-0 scale-95
}

.dropdown-enter-to {
  @apply transform opacity-100 scale-100
}

.dropdown-leave-active {
  @apply transition ease-in duration-75
}

.dropdown-leave-from {
  @apply transform opacity-100 scale-100
}

.dropdown-leave-to {
  @apply transform opacity-0 scale-95
}
</style>
