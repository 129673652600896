// noinspection JSUnusedGlobalSymbols
export default defineNuxtRouteMiddleware((to) => {
  // catch all (valid) legacy job postings
  const matchResult = to.fullPath.match(/^\/jobs\/([a-z0-9-]+)\/ausschreibung\.pdf$/)
  if (!matchResult) {
    return;
  }

  switch (matchResult[1]) {
    case 'developer':
      return navigateTo('/participate/game-developer', {redirectCode: 301})
    case 'designer':
      return navigateTo('/participate/graphic-artist', {redirectCode: 301})
    case 'video-producer':
      return navigateTo('/participate/video-producer', {redirectCode: 301})
    case 'builder':
      return navigateTo('/participate/builder', {redirectCode: 301})
    default:
      return navigateTo('/participate/', {redirectCode: 301})
  }
})
