import _moment from "moment/moment";

// languages
import 'moment/locale/de';

const moment = _moment

export function useMoment() {
  return {
    moment
  }
}
