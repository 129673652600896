export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    mounted (el, binding) {
      el.clickOutsideEvent = function (event: any) {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          binding.value(event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
    getSSRProps() {
        return {}
    },
  })
})
