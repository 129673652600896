import { isLegacyUsername } from "~/composables/charon";

// a direct mapping between legacy user ids to minecraft uuids
const mapping: Record<string, string> = {
  '1'  : '9c09eef4-f68d-4387-9751-72bbff53d5a0', // Scrayos
  '3'  : '13733464-5438-4d42-873f-ee356afe40c3', // ZPremio
  '5'  : '33bb1a16-8db4-4ea7-8922-cfe28622544e', // _Slik
  '9'  : '09879557-e479-45a9-b434-a56377674627', // Hydrofin
  '12' : '9c7432d7-3516-4e97-95f8-be8f520eb3b8', // Faulsocke
  '56' : '50dea916-7e8b-4c97-85f5-b59ec9e5baf3', // VVind0wM4ker
  '97' : '34dfd07d-26e6-4aee-b1c5-3241a88bc957', // ProfessorLogout
  '82' : 'e0553542-2c65-45a8-b0b0-6473bff678c2', // Aristos
  '124': '462c342f-dcb9-48eb-a338-62156808966d', // ilouHD
  '128': '1251ec9a-27a6-4a4e-b2a9-4d642828b78e', // LucaDev
  '130': '179ab6da-a849-4cc8-8581-4ca65d0397db', // ProfessorDevil
  '147': '7a3d152d-f72c-4e80-bef5-aedb6bff110d', // AtzeKrank
  '153': 'e46df07c-0286-40f7-b58f-5c0f8b1ad3c6', // P0sa
  '155': 'ea09c2ef-314f-436a-975c-dbc734fc8914', // MapeHD
  '182': 'ee8f6dda-6b35-4936-bdde-91d189719cc8', // theJanosch
  '184': 'fbb5daeb-ec55-4050-9bab-84674e179cae', // zaPuffi
  '236': '740dfff6-ccad-492a-87ee-1e74d4a08416', // Synistic
  '253': '862813d1-3487-4aae-9ca1-c5b3b7e5141d', // HSGMiner
  '263': '23986b57-5ba9-41bd-a4c4-3b43ca653b80', // Itz_Gina
  '265': 'afe61039-f4d4-422d-a82b-33eb4e37607c', // Spork
  '299': '2fbe3e3e-50c3-482c-872b-28386fd91704', // MCMDEV
  '312': '5196f1d6-c02a-4a1f-8da7-31d5d0a21fe0', // HoleyyMole
}

// noinspection JSUnusedGlobalSymbols
export default defineNuxtRouteMiddleware((to) => {
  // catch all (valid) legacy usernames (format: 'legacyId-legacyUsername')
  const userParam = to.params.user;
  if (to.meta.nameKey !== "user" || !userParam || typeof userParam !== 'string' || !isLegacyUsername(userParam)) {
    return;
  }
  const [ legacyId, legacyUsername ] = userParam.split('-')

  // redirect by direct mapping
  const uuid = mapping[legacyId]
  if (uuid) {
    // TODO use route name
    return navigateTo(`/user/${uuid}`, { redirectCode: 301 })
  }

  // redirect by legacy username (could not exist)
  // TODO use route name
  return navigateTo(`/user/${legacyUsername}`, { redirectCode: 301 })
})
