import type {News, Translatable, TranslatedNews} from "./types";
import { UserRole, UserRoleData, Department } from "./types";
import type { JSONContent } from "@tiptap/core";

export function isObjectId(value?: string): boolean {
  return !!value && !!value.match(/^[0-9a-fA-F]{24}$/)
}

export function isUUID(value?: string): boolean {
  return !!value && !!value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
}

export function isMinecraftUsername(value?: string): boolean {
  return !!value && !!value.match(/^[a-zA-Z0-9_]{2,16}$/i)
}

export function isLegacyUsername(value?: string): boolean {
  return !!value && !!value.match(/^[0-9]+-.{0,16}$/i)
}

export function isTeamRole(role: UserRole) {
  const department = UserRoleData[role].department
  return (department !== Department.PLAYER) && (department !== Department.TECHNICAL)
}

export function deepClone<T>(value: T): T {
  return structuredClone(toRaw(value))
}

export function translate<T>(trans: Translatable<T> | null | undefined, locale: string | null): T | null {
  if (!trans || !locale) {
    return null
  }
  return trans[locale] ?? trans["en"] ?? Object.entries(trans)[0]?.[1] ?? null
}

export function translateNews<T>(news: News<T> | null | undefined, locale: string): TranslatedNews<T> | null {
  if (!news) {
    return null
  }
  const trans = translate(news.locales, locale)
  if (trans == null) {
    return null
  }
  return {
    ...news,
    ...trans,
    locales: Object.keys(news.locales)
  }
}

// uses first paragraph of content as teaser
export function generateTeaser(content: JSONContent, length = 500): JSONContent | undefined {
  const para = content.content?.find(node => node.type === "paragraph")
  if (!para || !para.content) {
    console.warn('could not generate teaser from content', content)
    return undefined
  }
  let characterCount = 0
  const texts = para.content
    .filter(node => node.type === 'text')
    .filter(text => {
      characterCount += (text.text as string).length
      return characterCount < length
    })

  // remove any tailing colons and white spaces
  if (texts.length > 0) {
    texts[texts.length - 1].text = (texts[texts.length - 1].text as string).replace(/[.|\s]*$/, "")
  }

  return {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          ...texts,
          {
            type: 'text',
            text: '...'
          }
        ]
      }
    ]
  }
}
