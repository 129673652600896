<template>
  <div
    v-if="props.error !== null"
    class="flex flex-col laptop:flex-row gap-16 justify-center"
  >
    <NuxtPicture
      src="images/skin_renders/roles/game_developer.png"
      :alt="t('jc.component.errorCard.image.alt')"
      :title="t(`jc.component.errorCard.showMoreButton.title`)"
      class="hidden laptop:flex w-32"
    />

    <ContentCard
      :heading="t(`jc.component.errorCard.name.${ props.error['statusCode'] ?? 'fallback' }.label`, { name: props.error.name })"
      :paragraph="props.error['message'] ?? props.error['statusMessage'] ?? t(`jc.component.errorCard.message.${ props.error['statusCode'] ?? 'fallback' }.label`, { name: props.error.name })"
      class="my-auto"
    >
      <button
        class="bg-primary hover:bg-primary-highlighted active:bg-primary-selected disabled:bg-gray-500 text-white
          text-center rounded py-2 px-4 mt-10 font-semibold"
        @click="() => clearError({ redirect: '/' })"
      >
        {{ t('jc.component.errorCard.returnButton.label') }}
      </button>
    </ContentCard>
  </div>
</template>

<script setup lang="ts">
import type {NuxtError} from "#app";

const { t } = useI18n()

const props = defineProps<{
  error: NuxtError | Error | null
}>()
</script>

<style scoped lang="postcss">
:deep(img) {
  @apply w-full h-full object-cover object-top
}
</style>
