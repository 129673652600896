import { useToast } from "vue-toastification";

export function useCopyMinecraftIp() {
  const toast = useToast()
  const { t } = useI18n()
  const config = useRuntimeConfig()

  return () => navigator.clipboard.writeText(config.public.minecraftIp)
    .then(() => {
      toast.success(t('jc.composable.copyMinecraftIp.copySuccess'))
    })
    .catch(() => {
      toast.success(t('jc.composable.copyMinecraftIp.copyFailed'))
    })
}
