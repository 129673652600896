<template>
  <div
    :class="{
      'relative py-10': slots.background
    }"
  >
    <div
      v-if="slots.background"
      class="absolute inset-0"
      :class="{
        '-top-12': props.extendUp,
        '-bottom-12': props.extendDown,
      }"
    >
      <slot name="background" />
    </div>
    <div
      class="flex flex-col gap-10 w-content-item mx-auto"
      :class="{
        'relative z-10': slots.background
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  extendDown?: boolean
  extendUp?: boolean
}>()

const slots = useSlots()
</script>
