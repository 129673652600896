// noinspection JSUnusedGlobalSymbols
export default defineNuxtRouteMiddleware((to) => {
  // catch all (valid) legacy articles
  if (!to.fullPath.match(/^\/article\/[0-9]+-[0-9a-zA-Z\-]+\/?$/)) {
    return;
  }
  const newsSlug = to.fullPath.substring(to.fullPath.indexOf('-') + 1)
  // TODO use route name
  return navigateTo(`/news/${newsSlug}`, { redirectCode: 301 })
})
