import type { JSONContent } from "@tiptap/core";

/** Type alias for the MongoDB ObjectId. Is represented by a string. */
export type ObjectId = string

/** Type alias for the (Minecraft) UUIDv4. Is represented by a string. */
export type Uuid = string

/** Type alias for the (Java) Instant. Is represented by a string. */
export type Instant = string

/** Type alias for the supported locales. */
export type Locale = "de" | "en" | string

/** Composable for translatable fields. Holds a map of Locale to translatable type. */
export type Translatable<T> = Record<Locale, T>

/**
 * The different departments, the user roles are divided into. Use {@link DepartmentData} to access the static department
 * configuration.
 */
export enum Department {
  // noinspection JSUnusedGlobalSymbols
  MANAGEMENT = 'MANAGEMENT',
  DEVELOPMENT = 'DEVELOPMENT',
  CREATIVE = 'CREATIVE',
  PROMOTION = 'PROMOTION',
  SUPPORT = 'SUPPORT',
  PLAYER = 'PLAYER',
  CREATOR = 'CREATOR',
  PREMIUM = 'PREMIUM',
  TECHNICAL = 'TECHNICAL',
}

/**
 * The different user roles that a user can have. Are divided into {@link Department}s. Use {@link UserRoleData} to
 * access the static user role configuration.
 */
export enum UserRole {
  // noinspection JSUnusedGlobalSymbols
  // player department
  PLAYER = 'PLAYER',
  PREMIUM = 'PREMIUM',

  // creator department
  CREATOR = 'CREATOR',

  // creative department
  BUILDER = 'BUILDER',
  GAME_DESIGNER = 'GAME_DESIGNER',
  VIDEO_PRODUCER = 'VIDEO_PRODUCER',
  GRAPHIC_ARTIST = 'GRAPHIC_ARTIST',
  AUDIO_DESIGNER = 'AUDIO_DESIGNER',

  // development department
  WEB_DEVELOPER = 'WEB_DEVELOPER',
  GAME_DEVELOPER = 'GAME_DEVELOPER',
  INFRA_DEVELOPER = 'INFRA_DEVELOPER',

  // promotion department
  EDITOR = 'EDITOR',
  PROMOTER = 'PROMOTER',

  // support department
  SUPPORTER = 'SUPPORTER',
  MODERATOR = 'MODERATOR',

  // management department
  GAMEMASTER = 'GAMEMASTER',
  MANAGER = 'MANAGER',
  ADMINISTRATOR = 'ADMINISTRATOR',
}

/** The different ranks a clan member can have. */
export enum ClanMemberRank {
  // noinspection JSUnusedGlobalSymbols
  EXTERNAL = "EXTERNAL",
  RECRUIT = "RECRUIT",
  LIEUTENANT = "LIEUTENANT",
  COLONEL = "COLONEL",
}

/** The user object. Holds general information about a user. */
export interface User {
  id: Uuid
  name: string
  roles: UserRole[] | null
  globalFirstJoinMoment: Instant | null
  globalPreviousPlaytime: number | null
  globalLastQuitMoment: Instant | null
  experience: number | null
  level: number | null
  online: boolean
  muted: boolean
  banned: boolean
  team: boolean
  premium: boolean
}

export interface UserClashlands {
  id: Uuid
  name: string
  clan: Uuid
  memberRank: ClanMemberRank | null
  combatPoints: number | null
  warriorLevel: number | null
  serverFirstJoinMoment: Instant
  serverPreviousPlaytime: number
}

export interface UserDungeon {
  id: Uuid
  name: string
  progress: Record<string, string> | null
  endlessProgress: Record<string, number> | null
  serverFirstJoinMoment: Instant
  serverPreviousPlaytime: number
}

export interface UserVote {
  id: Uuid
  name: string
  votes: number | null
  streak:  number | null
  maxStreak: number | null
}

export interface Clan {
  id: Uuid
  name: string
  tag: string
  establishmentMoment: Instant
  founderId: Uuid
  members: Uuid[]
  elo: number | null
  combatPoints: number | null
  level: number
}

export interface DungeonEnvironment {
  identifier: string
  index: number
}

export interface DungeonSeason {
  id: Uuid
  from: string
  until: string
  number: number
}

export interface FileReference {
  id: ObjectId | null
  createDate: Date | null
  locales: Translatable<{
    description: string
  }>
  original: FileVariant
  // only visible if file write permission
  edited: FileVariant | null
  editData: {
    useWatermark: boolean | null
    useOverlayText: boolean | null
    overlayText: string | null
  } | null
}

export interface FileVariant {
  createDate: Date | null
  mediaType: string | null
  extension: string | null
}

export interface SitemapUser {
  id: Uuid
  globalLastQuitMoment: Instant | null
}

export interface SitemapNews {
  slug: string
  locale: Locale
  title: string
  updateDate: Instant
  publishDate: Instant
  image: ObjectId | null
  images: ObjectId[]
  videos: ObjectId[]
}

export interface ServerVersion {
  protocol: number
  displayName: string
  supportedVersions: string[]
}

export enum NewsType {
  // noinspection JSUnusedGlobalSymbols
  WEEKLY_REVIEW = 'WEEKLY_REVIEW',
  BLOG = 'BLOG',
  CHANGELOG = 'CHANGELOG',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
}

export interface News<T = JSONContent> {
  id: ObjectId | null
  createDate: Instant | null
  updateDate: Instant | null
  slug: string
  type: NewsType
  tags: ObjectId[]
  publishDate: Instant | null
  draft: boolean
  published: boolean | null
  locales: Translatable<{
    image: ObjectId | null
    title: T | null
    teaser: T | null
    body: T | null
  }>
}

export interface TranslatedNews<T = JSONContent> {
  id: ObjectId | null
  createDate: Instant | null
  updateDate: Instant | null
  slug: string
  type: NewsType
  tags: ObjectId[]
  publishDate: Instant | null
  draft: boolean
  published: boolean | null
  image: ObjectId | null
  title: T | null
  teaser: T | null
  body: T | null
  locales: Locale[]
}

export const enum TagNamespace {
  NEWS = 'NEWS'
}

export interface Tag {
  id: ObjectId | null
  namespace: TagNamespace
  locales: Translatable<{
    label: string
    description: string
  }>
}

export interface DungeonRun {
  mode: string
  season: number
  players: Uuid[]
  environment: string
  duration: number
  finishedLevel: number
}

export interface DungeonRunWithPlace extends DungeonRun{
  place: number
}

export interface DungeonChallengeEnvironment {
  id: ObjectId
  from: string
  until: string
  mutators: string[]
}

export const DepartmentData = {
  [Department.MANAGEMENT]: {
    nameKey: 'management',
    icon: 'landmark',
  },
  [Department.DEVELOPMENT]: {
    nameKey: 'development',
    icon: 'gear',
  },
  [Department.CREATIVE]: {
    nameKey: 'creative',
    icon: 'brush',
  },
  [Department.PROMOTION]: {
    nameKey: 'promotion',
    icon: 'megaphone',
  },
  [Department.SUPPORT]: {
    nameKey: 'support',
    icon: 'gavel',
  },
  [Department.CREATOR]: {
    nameKey: 'premium',
    icon: 'crown',
  },
  [Department.PREMIUM]: {
    nameKey: 'premium',
    icon: 'crown',
  },
  [Department.PLAYER]: {
    nameKey: 'players',
    icon: 'user',
  },
  [Department.TECHNICAL]: {
    nameKey: 'technical',
    icon: 'gears',
  },
}

export const UserRoleData = {
  [UserRole.PLAYER]: {
    nameKey: 'player',
    priority: 0,
    department: Department.PLAYER
  },
  [UserRole.PREMIUM]: {
    nameKey: 'premium',
    priority: 1,
    department: Department.PREMIUM
  },

  [UserRole.CREATOR]: {
    nameKey: 'creator',
    priority: 2,
    department: Department.CREATOR
  },

  [UserRole.BUILDER]: {
    nameKey: 'builder',
    priority: 3,
    department: Department.CREATIVE,
    routeName: 'participate-builder',
    skin: { src: "images/skin_renders/roles/builder.png" }
  },
  [UserRole.GAME_DESIGNER]: {
    nameKey: 'gameDesigner',
    priority: 4,
    department: Department.CREATIVE,
    routeName: 'participate-game-designer',
    skin: { src: "images/skin_renders/roles/questmaster.png" }
  },
  [UserRole.VIDEO_PRODUCER]: {
    nameKey: 'videoProducer',
    priority: 5,
    department: Department.CREATIVE,
    routeName: 'participate-video-producer',
    skin: { src: "images/skin_renders/roles/video_producer.png" }
  },
  [UserRole.GRAPHIC_ARTIST]: {
    nameKey: 'graphicArtist',
    priority: 6,
    department: Department.CREATIVE,
    routeName: 'participate-graphic-artist',
    skin: { src: "images/skin_renders/roles/graphic_artist.png" }
  },
  [UserRole.AUDIO_DESIGNER]: {
    nameKey: 'audioDesigner',
    priority: 7,
    department: Department.CREATIVE,
    routeName: 'participate-audio-designer',
    skin: { src: "images/skin_renders/roles/audio_designer.png" }
  },

  [UserRole.WEB_DEVELOPER]: {
    nameKey: 'webDeveloper',
    priority: 8,
    department: Department.DEVELOPMENT,
    routeName: 'participate-web-developer',
    skin: { src: "images/skin_renders/roles/web_developer.png" }
  },
  [UserRole.GAME_DEVELOPER]: {
    nameKey: 'gameDeveloper',
    priority: 9,
    department: Department.DEVELOPMENT,
    routeName: 'participate-game-developer',
    skin: { src: "images/skin_renders/roles/game_developer.png" }
  },
  [UserRole.INFRA_DEVELOPER]: {
    nameKey: 'infraDeveloper',
    priority: 10,
    department: Department.DEVELOPMENT,
    routeName: 'participate-infra-developer',
    skin: { src: "images/skin_renders/roles/infra_developer.png" }
  },

  [UserRole.EDITOR]: {
    nameKey: 'editor',
    priority: 11,
    department: Department.PROMOTION,
    routeName: 'participate-editor',
    skin: { src: "images/skin_renders/roles/editor.png" }
  },
  [UserRole.PROMOTER]: {
    nameKey: 'promoter',
    priority: 12,
    department: Department.PROMOTION,
    routeName: 'participate-promoter',
    skin: { src: "images/skin_renders/roles/pr_manager.png" }
  },

  [UserRole.SUPPORTER]: {
    nameKey: 'supporter',
    priority: 13,
    department: Department.SUPPORT,
    routeName: 'participate-supporter',
    skin: { src: "images/skin_renders/roles/supporter.png" }
  },
  [UserRole.MODERATOR]: {
    nameKey: 'moderator',
    priority: 14,
    department: Department.SUPPORT
  },

  [UserRole.GAMEMASTER]: {
    nameKey: 'gamemaster',
    priority: 15,
    department: Department.MANAGEMENT
  },
  [UserRole.MANAGER]: {
    nameKey: 'manager',
    priority: 16,
    department: Department.MANAGEMENT
  },
  [UserRole.ADMINISTRATOR]: {
    nameKey: 'administrator',
    priority: 17,
    department: Department.MANAGEMENT
  }
}
