import { joinURL } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL } = {}
) => {
  let operations = ""
  if (modifiers.scale !== undefined) {
    operations += operations ? "&" : "?"
    operations += `scale=${modifiers.scale}`
  }
  if (modifiers.overlay !== undefined) {
    operations += operations ? "&" : "?"
    operations += `overlay=${modifiers.overlay}`
  }

  // fix src uuid
  if (!src) {
    // valid uuid, make valid but will return not-found
    src = "00000000-0000-0000-0000-000000000000"
  }
  if (!src.includes("-")) {
    src = src.substring(0,9)
      + "-"
      + src.substring(9,13)
      + "-"
      + src.substring(13,17)
      + "-"
      + src.substring(17, 21)
      + "-"
      + src.substring(21)
  }

  return {
    url: joinURL(baseURL, src, `head` + operations),
  }
}
