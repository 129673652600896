// noinspection JSUnusedGlobalSymbols
import type {User} from "~/composables/charon";
import {isUUID, isMinecraftUsername} from "~/composables/charon";

export default defineNuxtRouteMiddleware(async (to) => {
  const { charon } = useCharon()
  const localePath = useLocalePath()

  // check that route contains user param (only for user page, check with meta.nameKey because of i18n)
  const userParam = to.params.user;
  if (to.meta.nameKey !== "user" || !userParam || typeof userParam !== 'string' || isUUID(userParam)) {
    return
  }

  // pre-filter invalid usernames
  if (!isMinecraftUsername(userParam)) {
    throw createError({
      statusCode: 404,
    })
  }

  const users = await charon<User[]>(`/users`,
    {
      params: {
        'name[fuzzyi]': userParam,
        size: 1,
        "foo": "test"
      }
    })
    .catch(reason => {
      const responseStatus = reason.response?.status ?? 500
      throw createError({
        statusCode: responseStatus,
      })
    })

  // validate found user (if any) by username
  if (users.length === 0 || users[0].name.toLowerCase() !== userParam.toLowerCase()) {
    throw createError({
      statusCode: 404,
    })
  }

  return navigateTo(
    localePath({
      name: "user-user",
      params: {
        user: users[0].id
      }
    }),
    { redirectCode: 308 }
  )
})
