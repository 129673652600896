import {library, config} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'

import {
  faSwords,
  faGamepadModern,
  faHandHoldingHeart,
  faMoneyBills,
  faUserGraduate,
  faHouseBuilding,
  faPencilPaintbrush,
  faUserCrown,
  faGameConsoleHandheld,
  faLightbulb,
  faHandshake,
  faUsers,
  faEye,
  faSignsPost,
  faStarShooting,
  faNewspaper,
  faCartShoppingFast,
  faListUl,
  faListOl,
  faPen,
  faPenRuler,
  faQuestion,
  faPlus,
  faInfo,
  faImage,
  faVideo,
  faUserPlus,
  faPlay,
  faSparkles,
  faGreaterThan,
  faArrowUp,
  faArrowRight,
  faArrowDown,
  faArrowLeft,
  faDownload,
  faArrowsRotate,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faUser,
  faBrainCircuit,
  faFlaskRoundPotion,
  faClockRotateLeft,
  faMicrochip,
  faCampground,
  faShieldCheck,
  faBrainArrowCurvedRight,
  faSitemap,
  faClock,
  faVialCircleCheck,
  faMagnifyingGlass,
  faBooks,
  faServer,
  faToolbox,
  faCubes,
  faCouch,
  faDna,
  faBookOpenReader,
  faGaugeMax,
  faCommentSmile,
  faBatteryFull,
  faAtomSimple,
  faCode,
  faCodeMerge,
  faScrewdriverWrench,
  faMicroscope,
  faFileLines,
  faHandsHoldingHeart,
  faGraduationCap,
  faComments,
  faBars,
  faXmark,
  faFlag,
  faSack,
  faFort,
  faUp,
  faTrophy,
  faBold,
  faItalic,
  faStrikethrough,
  faCodeSimple,
  faH1,
  faH2,
  faH3,
  faH4,
  faHeading,
  faPipe,
  faUnderline,
  faLinkSimple,
  faLinkSimpleSlash,
  faText,
  faCaretDown,
  faTrash,
  faPencil,
  faCheck,
  faRotateRight,
  faSpinner,
  faSpinnerThird,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faHorizontalRule,
  faSuperscript,
  faSubscript,
  faGalleryThumbnails,
  faBlockQuote,
  faRightToBracket,
  faRightFromBracket,
  faTimer,
  faFlagPennant,
  faAnglesUp,
  faCheckToSlot,
  faBarsStaggered,
  faEnvelope,
  faHandWave,
  faCube,
  faNetworkWired,
  faMonitorWaveform,
  faShieldHeart,
  faCameraCctv,
  faShieldPlus,
  faSidebarFlip,
  faArrowsLeftRightToLine,
  faSymbols,
  faMagnifyingGlassChart,
  faArrowsCross,
  faLink,
  faDragon,
  faWrench,
  faParachuteBox,
  faPaintbrushPencil,
  faPeopleArrowsLeftRight,
  faPeopleCarry,
  faAlicorn,
  faGopuram,
  faMountains,
  faMagnifyingGlassPlus,
  faMapLocation,
  faEarthAmerica,
  faFileCertificate,
  faImagePortrait,
  faGears,
  faFingerprint,
  faPaintbrush,
  faPalette,
  faHandHoldingMagic,
  faImages,
  faScissors,
  faCameraMovie,
  faMicrophone,
  faScroll,
  faPersonWalking,
  faListCheck,
  faIndustryWindows,
  faChartLineUp,
  faStamp,
  faGlobe,
  faListDropdown,
  faCommentPen,
  faUserGear,
  faScreenUsers,
  faNotebook,
  faScaleBalanced,
  faGavel,
  faPartyHorn,
  faHandsHoldingChild,
  faMusic,
  faGuitar,
  faCassetteTape,
  faMicrophoneStand,
  faExclamation,
  faLandmark,
  faGear,
  faBrush,
  faMegaphone,
  faFile,
  faFilePen,
  faFilePlus,
  faFileMinus,
  faFiles,
  faDungeon,
  faRabbitFast,
  faCommentsQuestionCheck,
  faTicket,
  faPhotoFilm,
  faMessageSmile,
  faPenField,
  faCommentPlus,
  faUserMagnifyingGlass,
  faCopy,
  faFileBinary,
  faUpload,
  faSave,
  faRepeat,
  faCalendarStar,
  faFilm,
  faArrowTrendUp,
  faFaceSmile,
  faShield,
  faFaceSwear,
  faHandPointRight,
  faSlash,
  faSirenOn,
  faHandFist,
  faBrain,
  faBillboard,
  faUserAstronaut,
  faEnvelopesBulk,
  faPeopleGroup,
  faFacePleading,
  faBug,
  faHouseFlag,
  faCoins,
  faHatWizard,
  faLanguage,
  faHandHoldingDollar,
  faCircleQuestion,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faDiscord,
  faTeamspeak,
  faInstagram,
  faFacebookSquare,
  faTwitter,
  faYoutube,
  faTwitch,
  faTiktok,
  faMastodon,
  faWhatsapp,
  faThreads,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons'

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

library.add(
  // fas
  faSwords,
  faGamepadModern,
  faHandHoldingHeart,
  faMoneyBills,
  faUserGraduate,
  faHouseBuilding,
  faPencilPaintbrush,
  faUserCrown,
  faGameConsoleHandheld,
  faLightbulb,
  faHandshake,
  faUsers,
  faEye,
  faSignsPost,
  faStarShooting,
  faNewspaper,
  faCartShoppingFast,
  faListUl,
  faListOl,
  faPen,
  faPenRuler,
  faQuestion,
  faPlus,
  faInfo,
  faImage,
  faVideo,
  faUserPlus,
  faPlay,
  faSparkles,
  faGreaterThan,
  faArrowUp,
  faArrowRight,
  faArrowDown,
  faArrowLeft,
  faDownload,
  faArrowsRotate,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faUser,
  faBrainCircuit,
  faFlaskRoundPotion,
  faClockRotateLeft,
  faMicrochip,
  faCampground,
  faShieldCheck,
  faBrainArrowCurvedRight,
  faSitemap,
  faClock,
  faVialCircleCheck,
  faMagnifyingGlass,
  faBooks,
  faServer,
  faToolbox,
  faCubes,
  faCouch,
  faDna,
  faBookOpenReader,
  faGaugeMax,
  faCommentSmile,
  faBatteryFull,
  faAtomSimple,
  faCode,
  faCodeMerge,
  faScrewdriverWrench,
  faMicroscope,
  faFileLines,
  faHandsHoldingHeart,
  faGraduationCap,
  faComments,
  faBars,
  faXmark,
  faFlag,
  faSack,
  faFort,
  faUp,
  faTrophy,
  faBold,
  faItalic,
  faStrikethrough,
  faCodeSimple,
  faH1,
  faH2,
  faH3,
  faH4,
  faHeading,
  faPipe,
  faUnderline,
  faLinkSimple,
  faLinkSimpleSlash,
  faText,
  faCaretDown,
  faTrash,
  faPencil,
  faCheck,
  faRotateRight,
  faSpinner,
  faSpinnerThird,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faHorizontalRule,
  faSuperscript,
  faSubscript,
  faGalleryThumbnails,
  faBlockQuote,
  faRightToBracket,
  faRightFromBracket,
  faTimer,
  faFlagPennant,
  faAnglesUp,
  faCheckToSlot,
  faBarsStaggered,
  faEnvelope,
  faHandWave,
  faCube,
  faNetworkWired,
  faMonitorWaveform,
  faShieldHeart,
  faCameraCctv,
  faShieldPlus,
  faSidebarFlip,
  faArrowsLeftRightToLine,
  faSymbols,
  faMagnifyingGlassChart,
  faArrowsCross,
  faLink,
  faDragon,
  faWrench,
  faParachuteBox,
  faPaintbrushPencil,
  faPeopleArrowsLeftRight,
  faPeopleCarry,
  faAlicorn,
  faGopuram,
  faMountains,
  faMagnifyingGlassPlus,
  faMapLocation,
  faEarthAmerica,
  faFileCertificate,
  faImagePortrait,
  faGears,
  faFingerprint,
  faPaintbrush,
  faPalette,
  faHandHoldingMagic,
  faImages,
  faScissors,
  faCameraMovie,
  faMicrophone,
  faScroll,
  faPersonWalking,
  faListCheck,
  faIndustryWindows,
  faChartLineUp,
  faStamp,
  faGlobe,
  faListDropdown,
  faCommentPen,
  faUserGear,
  faScreenUsers,
  faNotebook,
  faScaleBalanced,
  faGavel,
  faPartyHorn,
  faHandsHoldingChild,
  faMusic,
  faGuitar,
  faCassetteTape,
  faMicrophoneStand,
  faExclamation,
  faLandmark,
  faGear,
  faBrush,
  faMegaphone,
  faFile,
  faFilePen,
  faFilePlus,
  faFileMinus,
  faFiles,
  faDungeon,
  faRabbitFast,
  faCommentsQuestionCheck,
  faTicket,
  faPhotoFilm,
  faMessageSmile,
  faPenField,
  faCommentPlus,
  faUserMagnifyingGlass,
  faCopy,
  faFileBinary,
  faUpload,
  faSave,
  faRepeat,
  faCalendarStar,
  faFilm,
  faArrowTrendUp,
  faFaceSmile,
  faShield,
  faFaceSwear,
  faHandPointRight,
  faSlash,
  faSirenOn,
  faHandFist,
  faBrain,
  faBillboard,
  faMegaphone,
  faUserAstronaut,
  faEnvelopesBulk,
  faPeopleGroup,
  faFacePleading,
  faBug,
  faCoins,
  faHatWizard,
  faLanguage,
  faHandHoldingDollar,
  faCircleQuestion,
  faTriangleExclamation,
  // fab
  faDiscord,
  faTeamspeak,
  faInstagram,
  faFacebookSquare,
  faTwitter,
  faYoutube,
  faTwitch,
  faTiktok,
  faMastodon,
  faWhatsapp,
  faThreads,
  faXTwitter,
  faHouseFlag,
)

export default defineNuxtPlugin((nuxtApp) => {
  // @ts-ignore
  nuxtApp.vueApp
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('font-awesome-layers', FontAwesomeLayers)
    .component('font-awesome-layers-text', FontAwesomeLayersText)
})
