<template>
  <div
    class="flex flex-col min-h-screen bg-light"
  >
    <the-navbar />

    <main
      id="content"
      class="flex flex-col gap-24 mt-10 mb-40"
    >
      <Container>
        <ErrorCard
          :error="props.error"
        />
      </Container>
    </main>

    <the-footer
      class="mt-auto"
    />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";

definePageMeta({
  layout: 'default'
})

const { t } = useI18n()

const props = defineProps<{
  error: NuxtError | Error | null
}>()
</script>
